import {Component, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {RouterLink, RouterOutlet} from "@angular/router";
import {Subscription} from "rxjs";
import {AuthService} from "../../authentication/service/auth.service";

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    NgIf,
    RouterOutlet,
    RouterLink
  ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent implements OnInit {

  user?: any;
  userSubscription: Subscription = new Subscription();

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.getCurrentUser();
  }

  onLogin(): any {
    this.authService.initiateSSOAuthentication();
  }

  onLogout(): void {
    this.authService.logout();
  }

  getCurrentUser(): any {
    this.userSubscription = this.authService.currentUserSubject.subscribe(
      {next: (user) => {this.user = user}}
    );
    this.authService.getCurrentUser();
  }
}
