<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="''">Alpy</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">

        <li *ngIf="user" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            AlpAuth
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" [routerLink]="'user'">Utilisateurs</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" [routerLink]="'application'">Applications</a></li>
            <li><a class="dropdown-item" [routerLink]="'role'">Rôles</a></li>
          </ul>
        </li>


      </ul>

      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li *ngIf="user" class="nav-item">
          <a class="nav-link" href="#">{{user.username}}</a>
        </li>
        <li class="nav-item">
          <a *ngIf="!user" class="nav-link" (click)="onLogin()">Se connecter</a>
          <a *ngIf="user" class="nav-link" (click)="onLogout()">Se déconnecter</a>
        </li>
      </ul>

    </div>
  </div>
</nav>
