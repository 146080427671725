import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {User} from "../../../model/User";
import {Observer, Subscription} from "rxjs";
import {UserService} from "../../../service/userService/user.service";
import {UserUpsertComponent} from "../user-upsert/user-upsert.component";

@Component({
  selector: 'app-user-list',
  standalone: true,
    imports: [
        NgForOf
    ],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.scss'
})
export class UserListComponent implements OnInit, OnDestroy {

  private modalService = inject(NgbModal);

  usersList: User[] = [];
  usersListSubscription: Subscription = new Subscription();
  usersListObserver!: Observer<User[]>;

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.initObserver();
    this.getAllUsers();
  }

  ngOnDestroy(): void {
    this.usersListSubscription.unsubscribe();
  }

  initObserver() {
    this.usersListObserver = {
      next: (users: User[]) => {
        users.sort((a: User, b: User) => a.id > b.id ? 1 : -1)
        this.usersList = users;
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des users', error);
      },
      complete: () => {

      }
    }
  }

  getAllUsers(): void {
    this.usersListSubscription = this.userService.userListSubject.subscribe(this.usersListObserver);
    this.userService.getUsers();
  }

  open(userId?: number) {
    const modalRef = this.modalService.open(UserUpsertComponent, { size: 'xl' });
    modalRef.result.then(
      (result) => {
        if(result == 'created' || result == 'updated') {

        }
      },
      (result) => {

      }
    );
    modalRef.componentInstance.id = userId;
  }

  deleteUser(userId: number): void {
    this.userService.deleteUser(userId);
  }



}
