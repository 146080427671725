import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Role} from "../../../model/Role";
import {CommonModule} from "@angular/common";
import {RoleService} from "../../../service/roleService/role.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RoleUpsertComponent} from "../role-upsert/role-upsert.component";
import {Observer, Subscription} from "rxjs";

@Component({
  selector: 'app-role-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './role-list.component.html',
  styleUrl: './role-list.component.scss'
})
export class RoleListComponent implements OnInit, OnDestroy {

  private modalService = inject(NgbModal);

  rolesList: Role[] = [];
  rolesListSubscription: Subscription = new Subscription();
  rolesListObserver!: Observer<Role[]>;

  constructor(private roleService: RoleService) { }

  ngOnInit(): void {
    this.initObserver();
    this.getAllRoles();
  }

  ngOnDestroy(): void {
    this.rolesListSubscription.unsubscribe();
  }

  initObserver() {
    this.rolesListObserver = {
      next: (roles: Role[]) => {
        roles.sort((a: Role, b: Role) => a.id > b.id ? 1 : -1)
        this.rolesList = roles;
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des rôles', error);
      },
      complete: () => {

      }
    }
  }

  getAllRoles(): void {
    this.rolesListSubscription = this.roleService.roleListSubject.subscribe(this.rolesListObserver);
    this.roleService.getRoles();
  }

  open(roleId?: number) {
    const modalRef = this.modalService.open(RoleUpsertComponent, { size: 'xl' });
    modalRef.result.then(
      (result) => {
        if(result == 'created' || result == 'updated') {

        }
      },
      (result) => {

      }
    );
    modalRef.componentInstance.id = roleId;
  }

  deleteRole(roleId: number): void {
    this.roleService.deleteRole(roleId);
  }


}
