import {Component, OnInit, TemplateRef} from '@angular/core';
import {NgbAlert, NgbToast} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../service/toastService/toast.service";
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [
    NgbToast,
    NgForOf,
    NgIf,
    NgbAlert
  ],
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss'
})
export class ToastComponent implements OnInit {
  constructor(public toastService: ToastService) { }

  ngOnInit() {
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  close($event: any, toast: any) {
    this.toastService.remove(toast);
  }
}
