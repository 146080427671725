<div class="container">

  <div class="globalAction">
    <button class="btn btn-info" (click)="open()">
      <i class="bi bi-plus"></i>
    </button>
  </div>

  <table class="table table-hover">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Nom</th>
      <th scope="col">URI de redirection</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let application of applicationsList">
      <th scope="row">{{ application.id }}</th>
      <td>{{ application.name }}</td>
      <td>{{ application.redirectURI }}</td>
      <td>
        <button class="btn btn-success" (click)="open(application.id)">
          <i class="bi bi-pencil"></i>
        </button>
        <button class="btn btn-danger" (click)="deleteApplication(application.id)">
          <i class="bi bi-trash"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

</div>
