<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [header]="toast.header ? toast.header : ' '"
  [autohide]="!!toast.delay"
  [delay]="toast.delay ? toast.delay : 10000"
  (hidden)="toastService.remove(toast)"
>
  <svg
    *ngIf="toast.iconSVG"
    xmlns="http://www.w3.org/2000/svg"
    [attr.fill]="toast.iconSVG.fill"
    [attr.width]="toast.iconSVG.width"
    [attr.height]="toast.iconSVG.height"
    [attr.viewBox]="toast.iconSVG.viewBox">
      <path
        [attr.d]="toast.iconSVG.path"
      />
  </svg>
  <i
    *ngIf="toast.icon"
    [class]="toast.icon"></i>
  {{ toast.body }}
</ngb-toast>

