import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {User} from "../../model/User";
import {Observable, Observer, Subject, Subscription} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../../authentication/service/auth.service";
import {ApiPath} from "../ApiPathEnum";
import {ToastService} from "../toastService/toast.service";
import {Toast} from "../../model/dto/toast/Toast";
import {ErrorMappingService} from "../errorMappingService/error-mapping.service";

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  baseUrl = environment.baseUrl;

  userList!: User[];
  userListSubject = new Subject<any>;

  allUserSubscription: Subscription = new Subscription();
  allUserObserver!: Observer<User[]>;

  createUserSubscription: Subscription = new Subscription();
  createUserObserver!: Observer<User>;

  updateUserSubscription: Subscription = new Subscription();
  updateUserObserver!: Observer<User>;

  deleteUserSubscription: Subscription = new Subscription();
  deleteUserObserver!: Observer<null>;

  constructor(private http: HttpClient,
              private authService: AuthService,
              private toastService: ToastService,
              private errorMappingService: ErrorMappingService) {
    this.initObserver();
  }

  ngOnDestroy() {
    this.allUserSubscription.unsubscribe();
    this.createUserSubscription.unsubscribe();
    this.updateUserSubscription.unsubscribe();
    this.deleteUserSubscription.unsubscribe();
  }

  initObserver() {
    this.allUserObserver = {
      next: (userList: User[]) => {
        this.userList = userList;
        this.emitUserList();
      },
      error: (err: any) => {
        const toast: Toast = {
          header: 'Erreur',
          body: this.errorMappingService.getErrorMessageFromBackendCode(err.error),
          delay: 10000,
          classname: 'bg-danger text-light',
          icon: 'bi bi-x-circle'
        }
        this.toastService.show(toast)
      },
      complete: () => {}
    };
    this.createUserObserver = {
      next: (user: User) => {
        const toast: Toast = {
          header: 'Ajout d\'un utilisateur' ,
          body: 'L\'utilisateur ' + user.username + ' a été ajouté' ,
          delay: 5000,
          classname: 'bg-success text-light',
          icon: 'bi bi-person-add'
        }
        this.toastService.show(toast)
        this.getUsers();
      },
      error: (err: any) => {
        const toast: Toast = {
          header: 'Erreur' ,
          body: this.errorMappingService.getErrorMessageFromBackendCode(err.error),
          delay: 5000,
          classname: 'bg-danger text-light',
          icon: 'bi bi-person-slash'
        }
        this.toastService.show(toast)
      },
      complete: () => {},
    };
    this.updateUserObserver = {
      next: (user: User) => {
        const toast: Toast = {
          header: 'Modification d\'un utilisateur' ,
          body: 'L\'utilisateur ' + user.username + ' a été modifié' ,
          delay: 5000,
          classname: 'bg-success text-light',
          icon: 'bi bi-person-check'
        }
        this.toastService.show(toast)
        this.getUsers();
      },
      error: (err: any) => {
        const toast: Toast = {
          header: 'Erreur' ,
          body: this.errorMappingService.getErrorMessageFromBackendCode(err.error),
          delay: 5000,
          classname: 'bg-danger text-light',
          icon: 'bi bi-person-slash'
        }
        this.toastService.show(toast)
      },
      complete: () => {},
    };
    this.deleteUserObserver = {
      next: () => {
        const toast: Toast = {
          header: 'Suppression d\'un utilisateur' ,
          body: 'L\'utilisateur a été supprimé' ,
          delay: 5000,
          classname: 'bg-success text-light',
          icon: 'bi bi-person-check'
        }
        this.toastService.show(toast)
        this.getUsers();
      },
      error: (err: any) => {
        const toast: Toast = {
          header: 'Erreur' ,
          body: this.errorMappingService.getErrorMessageFromBackendCode(err.error),
          delay: 5000,
          classname: 'bg-danger text-light',
          icon: 'bi bi-person-slash'
        }
        this.toastService.show(toast)
      },
      complete: () => {},
    };
  }

  getUsers(): void {
    //vérification du token
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.User}/all`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.allUserSubscription = this.http.get<User[]>(url, { headers: headers }).subscribe(this.allUserObserver);
  }

  emitUserList(): void {
    const userList = this.userList;
    this.userListSubject.next(userList);
  }

  getUser(id: number): Observable<User> {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.User}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.http.get<User>(url, { headers: headers });
  }

  createUser(user: User): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.User}/`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.createUserSubscription = this.http.post<User>(url, user, { headers: headers }).subscribe(this.createUserObserver);
  }

  updateUser(id: number, user: User): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.User}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });


    this.updateUserSubscription = this.http.put<User>(url, user, { headers: headers }).subscribe(this.updateUserObserver);
  }

  deleteUser(id: number): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.User}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.deleteUserSubscription = this.http.delete<null>(url, { headers: headers }).subscribe(this.deleteUserObserver);
  }
}
