import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgForOf} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Application} from "../../../model/Application";
import {Observer, Subscription} from "rxjs";
import {ApplicationService} from "../../../service/applicationService/application.service";
import {ApplicationUpsertComponent} from "../../applications/application-upsert/application-upsert.component";

@Component({
  selector: 'app-application-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './application-list.component.html',
  styleUrl: './application-list.component.scss'
})
export class ApplicationListComponent implements OnInit, OnDestroy {

  private modalService = inject(NgbModal);

  applicationsList: Application[] = [];
  applicationsListSubscription: Subscription = new Subscription();
  applicationsListObserver!: Observer<Application[]>;

  constructor(private applicationService: ApplicationService) { }

  ngOnInit(): void {
    this.initObserver();
    this.getAllApplications();
  }

  ngOnDestroy(): void {
    this.applicationsListSubscription.unsubscribe();
  }

  initObserver() {
    this.applicationsListObserver = {
      next: (applications: Application[]) => {
        applications.sort((a: Application, b: Application) => a.id > b.id ? 1 : -1)
        this.applicationsList = applications;
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des rôles', error);
      },
      complete: () => {

      }
    }
  }

  getAllApplications(): void {
    this.applicationsListSubscription = this.applicationService.applicationListSubject.subscribe(this.applicationsListObserver);
    this.applicationService.getApplications();
  }

  open(applicationId?: number) {
    console.log(applicationId);
    const modalRef = this.modalService.open(ApplicationUpsertComponent, { size: 'xl' });
    modalRef.result.then(
      (result) => {
        if(result == 'created' || result == 'updated') {

        }
      },
      (result) => {

      }
    );
    modalRef.componentInstance.id = applicationId;
  }

  deleteApplication(applicationId: number): void {
    this.applicationService.deleteApplication(applicationId);
  }


}
