<form [formGroup]="crudForm" (ngSubmit)="onSubmit()">

  <div class="modal-header">
    <h4 class="modal-title">Hi there!</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="mb-3" *ngIf="existingRole">
        <label for="id" class="form-label">id</label>
        <input type="text" class="form-control" id="id" formControlName="id">
      </div>
      <div class="mb-3">
        <label for="name" class="form-label">Nom</label>
        <input type="text" class="form-control" id="name" formControlName="name">
      </div>
    </div>

    <div class="mb-3">
      <label for="level" class="form-label">Niveau</label>
      <input type="number" class="form-control" id="level" formControlName="level">
    </div>

    <div class="mb-3">
      <label for="observation" class="form-label">Observation</label>
      <textarea class="form-control" id="observation" rows="3" formControlName="observation"></textarea>
    </div>



  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('close')">Close</button>
    <button type="submit" class="btn btn-primary" [disabled]="!crudForm.valid">Submit</button>
  </div>
</form>
