import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Application} from "../../model/Application";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../../authentication/service/auth.service";
import {ApiPath} from "../ApiPathEnum";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  baseUrl = environment.baseUrl;

  applicationList!: Application[];
  applicationListSubject = new Subject<any>;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getApplications(): void {
    //vérification du token
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Application}/all`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.get<Application[]>(url, { headers: headers }).subscribe(
      (applications: Application[]) => {
        this.applicationList = applications;
        this.emitApplicationList();
      }
    );
  }

  emitApplicationList(): void {
    const applicationList = this.applicationList;
    this.applicationListSubject.next(applicationList);
  }

  getApplication(id: number): Observable<Application> {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Application}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.http.get<Application>(url, { headers: headers });
  }

  createApplication(application: Application): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Application}/`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    //TODO : Add error manage
    this.http.post<Application>(url, application, { headers: headers }).subscribe(
      () => {
        this.getApplications();
      }
    );
  }

  updateApplication(id: number, application: Application): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Application}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    //TODO : Add error manage
    this.http.put<Application>(url, application, { headers: headers }).subscribe(
      () => {
        this.getApplications();
      }
    );
  }

  deleteApplication(id: number): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Application}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    //TODO : Add error manage
    this.http.delete<Application>(url, { headers: headers }).subscribe(
      () => {
        this.getApplications();
      }
    );
  }
}
