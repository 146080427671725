import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Role} from "../../../model/Role";
import {RoleService} from "../../../service/roleService/role.service";
import {Observer, Subscription} from "rxjs";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-role-upsert',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './role-upsert.component.html',
  styleUrl: './role-upsert.component.scss'
})
export class RoleUpsertComponent implements OnInit, OnDestroy {
  activeModal = inject(NgbActiveModal);
  crudForm!: FormGroup;

  existingRole?: Role;
  roleSubscription: Subscription = new Subscription();
  roleObserver!: Observer<Role>;

  @Input() id?: number;

  constructor(private roleService: RoleService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if(this.id) {
      this.initObserver();
      this.getExistingRole();
    }
    this.initDefaultForm();
  }

  ngOnDestroy(): void {
    this.roleSubscription.unsubscribe();
  }

  initObserver() {

    this.roleObserver = {
      next: (role: Role) => {
        this.existingRole = role;
        this.initExistingForm();
      },
      error: (error) => {
        console.error('Erreur lors de la récupération du rôle', error);
      },
      complete: () => {

      }
    }
  }

  private getExistingRole(): void {
    this.roleSubscription = this.roleService.getRole(this.id!).subscribe(this.roleObserver);
  }

  private initExistingForm() {
    if(this.existingRole) {
      this.crudForm = this.fb.group({
        id: [{value: this.existingRole.id, disabled: true}],
        name: [{value: this.existingRole.name, disabled: true}, Validators.required],
        level: [this.existingRole.level, Validators.required],
        observation: [this.existingRole.observation],
      });
    }else {
      this.initDefaultForm();
    }
  }

  private initDefaultForm() {
    this.crudForm = this.fb.group({
      name: ['', Validators.required],
      level: [0, Validators.required],
      observation: [''],
    });
  }

  onSubmit(): any {

    let role: Role;

    role = {
      id: 0,
      name: this.crudForm.get('name')!.value,
      level: this.crudForm.get('level')!.value,
      observation: this.crudForm.get('observation')?.value
    }

    if(this.existingRole) {
      this.roleService.updateRole(this.id!, role);
      this.activeModal.close('updated');
    }else {
      this.roleService.createRole(role);
      this.activeModal.close('created');
    }


  }


}
