<div class="container">

  <div class="globalAction">
    <button class="btn btn-info" (click)="open()">
      <i class="bi bi-plus"></i>
    </button>
  </div>

  <table class="table table-hover">
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Username</th>
      <th scope="col">Password</th>
      <th scope="col">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of usersList">
      <th scope="row">{{ user.id }}</th>
      <td>{{ user.username }}</td>
      <td>{{ user.password }}</td>
      <td>
        <button class="btn btn-success" (click)="open(user.id)">
          <i class="bi bi-pencil"></i>
        </button>
        <button class="btn btn-danger" (click)="deleteUser(user.id)">
          <i class="bi bi-trash"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>

</div>
