import {Component, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {Role} from "../../model/Role";
import {environment} from "../../../environments/environment";
import {ApiPath} from "../ApiPathEnum";
import {AuthService} from "../../authentication/service/auth.service";

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  baseUrl = environment.baseUrl;

  roleList!: Role[];
  roleListSubject = new Subject<any>;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getRoles(): void {
    //vérification du token
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Role}/all`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    this.http.get<Role[]>(url, { headers: headers }).subscribe(
      (roles: Role[]) => {
        this.roleList = roles;
        this.emitRoleList();
      }
    );
  }

  emitRoleList(): void {
    const roleList = this.roleList;
    this.roleListSubject.next(roleList);
  }

  getRole(id: number): Observable<Role> {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Role}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.http.get<Role>(url, { headers: headers });
  }

  createRole(role: Role): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Role}/`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    //TODO : Add error manage
    this.http.post<Role>(url, role, { headers: headers }).subscribe(
      () => {
        this.getRoles();
      }
    );
  }

  updateRole(id: number, role: Role): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Role}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    //TODO : Add error manage
    this.http.put<Role>(url, role, { headers: headers }).subscribe(
      () => {
        this.getRoles();
      }
    );
  }

  deleteRole(id: number): void {
    const token: string | null = this.authService.getCurrentToken();

    let url = `${this.baseUrl}/${ApiPath.Role}/${id}`;

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    //TODO : Add error manage
    this.http.delete<Role>(url, { headers: headers }).subscribe(
      () => {
        this.getRoles();
      }
    );
  }

}
