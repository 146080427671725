<form [formGroup]="crudForm" (ngSubmit)="onSubmit()" *ngIf="crudForm">

  <div class="modal-header">
    <h4 class="modal-title" *ngIf="!existingUser">Ajout d'un utilisateur</h4>
    <h4 class="modal-title" *ngIf="existingUser">Modification de {{existingUser!.username}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">

    <div class="row">
      <div class="mb-3" *ngIf="existingUser">
        <label for="id" class="form-label">id</label>
        <input type="text" class="form-control" id="id" formControlName="id">
      </div>
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input type="text" class="form-control" id="username" formControlName="username">
      </div>
    </div>

    <div class="mb-3">
      <label for="password" class="form-label">Password</label>
      <input type="password" class="form-control" id="password" formControlName="password" [placeholder]="existingUser? '***********' : ''">
    </div>
    <table class="table" *ngIf="existingUser">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let role of allRoles">{{ role.name }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let application of allApplications">
          <th scope="row">{{ application.name }}</th>
          <td *ngFor="let role of allRoles">
            <input type="checkbox"
                   [checked]="roleMatrix[role.id][application.id]"
                   (change)="onChangeCheckbox(role.id, application.id, $event)"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('close')">Close</button>
    <button type="submit" class="btn btn-primary" [disabled]="!crudForm.valid">Submit</button>
  </div>
</form>
