import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "./service/auth.service";
import {inject} from "@angular/core";
import { map } from 'rxjs/operators';
import {catchError, Observable, of} from "rxjs";

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);



  return authService
    .isLoggedIn().pipe(
    map(e => {
      if(e) {
        return true;
      }else {
        authService.initiateSSOAuthentication();
        return false;
      }
    }),
    catchError((err) => {
      authService.initiateSSOAuthentication();
      return of(false);
    })
  );
};
