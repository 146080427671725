import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../service/auth.service";
import {ActivatedRoute, Params} from "@angular/router";

@Component({
  selector: 'app-callback',
  standalone: true,
  imports: [],
  templateUrl: './callback.component.html',
  styleUrl: './callback.component.scss'
})
export class CallbackComponent implements OnInit{

  token: string = '';

  constructor(private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((queryParams) => {
      const ssoData: Params = queryParams; // ou accédez aux données postées d'une autre manière


      this.token = ssoData['token'];

      this.authService.handleSSORedirect(this.token);

    });

  }

}
