import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {User} from "../../../model/User";
import { Observer, Subscription} from "rxjs";
import {UserService} from "../../../service/userService/user.service";
import {KeyValuePipe, NgForOf, NgIf} from "@angular/common";
import {Role} from "../../../model/Role";
import {RoleService} from "../../../service/roleService/role.service";
import {Application} from "../../../model/Application";
import {ApplicationService} from "../../../service/applicationService/application.service";

@Component({
  selector: 'app-user-upsert',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    KeyValuePipe,
    NgForOf
  ],
  templateUrl: './user-upsert.component.html',
  styleUrl: './user-upsert.component.scss'
})
export class UserUpsertComponent implements OnInit, OnDestroy {
  activeModal = inject(NgbActiveModal);
  crudForm!: FormGroup;

  isLoaded: boolean = false;

  existingUser?: User;
  userSubscription: Subscription = new Subscription();
  userObserver!: Observer<User>;

  allRoles!: Role[];
  allRolesSubscription: Subscription = new Subscription();
  allRolesObserver!: Observer<Role[]>;

  allApplications!: Application[];
  allAplicationsSubscription: Subscription = new Subscription();
  allApplicationsObserver!: Observer<Application[]>;

  roleMatrix: Record<string, Record<string, boolean>> = {};

  observerNextReceive: Record<string, boolean> = {}

  @Input() id?: number;

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private applicationService: ApplicationService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.initObserver();
    if(this.id) {
      this.getAllRoles();
      this.getAllApplications();
      this.getExistingUser();
    }else {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.allRolesSubscription.unsubscribe();
    this.allAplicationsSubscription.unsubscribe();
  }

  initObserver() {
    this.observerNextReceive['user'] = false;
    this.observerNextReceive['role'] = false;
    this.observerNextReceive['application'] = false;

    this.userObserver = {
      next: (user: User) => {
        this.existingUser = user;
        this.observerNextReceive['user'] = true;
        if(this.observerNextReceive['role'] && this.observerNextReceive['application']) {
          this.initForm();
        }
      },
      error: (error) => {
        console.error('Erreur lors de la récupération de l\'utilisateur', error);
      },
      complete: () => {

      }
    }

    this.allRolesObserver = {
      next: (allRole: Role[]) => {
        this.allRoles = allRole;
        this.observerNextReceive['role'] = true;
        if(this.observerNextReceive['user'] && this.observerNextReceive['application']) {
          this.initForm();
        }
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des rôles', error);
      },
      complete: () => {

      }
    }

    this.allApplicationsObserver = {
      next: (allApplication: Application[]) => {
        this.allApplications = allApplication;
        this.observerNextReceive['application'] = true;
        if(this.observerNextReceive['user'] && this.observerNextReceive['role']) {
          this.initForm();
        }
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des applications', error);
      },
      complete: () => {

      }
    }
  }

  private getExistingUser(): void {
    this.userSubscription = this.userService.getUser(this.id!).subscribe(this.userObserver);
  }

  private getAllRoles(): void {
    this.allRolesSubscription = this.roleService.roleListSubject.subscribe(this.allRolesObserver);
    this.roleService.getRoles();
  }

  private getAllApplications(): void {
    this.allAplicationsSubscription = this.applicationService.applicationListSubject.subscribe(this.allApplicationsObserver);
    this.applicationService.getApplications();
  }

  private initMatrixRole(): void {
    if(this.allApplications && this.allRoles) {
      this.allRoles.forEach((role: Role) => {
        if (!this.roleMatrix[role.id]) {
          this.roleMatrix[role.id] = {};
        }
        this.allApplications.forEach((application: Application) => {
          this.roleMatrix[role.id][application.id] = this.hasRole(role.id, application.id);
        })
      });
    }
  }
  private initForm() {
    if(this.existingUser) {
      this.initMatrixRole();
      this.crudForm = this.fb.group({
        id: [{ value: this.existingUser.id, disabled: true }],
        username: [{ value: this.existingUser.username, disabled: true }, Validators.required],
        password: [this.existingUser.password]
      });
    } else {
      this.crudForm = this.fb.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
      });
    }


  }

  hasRole(roleId: number, applicationId: number): boolean {
    let result: boolean = false;
    this.existingUser?.userRoleList.forEach(
      userRole => {
        if(userRole.role.id == roleId && userRole.application.id == applicationId) {
          result = true;
        }
      }
    );
    return result;
  }
  onChangeCheckbox(roleId: number, applicationId: number, event: any) {
    const isNowChecked: boolean = event.target.checked;
    if(isNowChecked) {
      this.addRole(roleId, applicationId);
    }else {
      this.removeUserRole(roleId, applicationId);
    }
  }

  private addRole(roleId: number, applicationId: number) {
    this.existingUser!.userRoleList.push({user: {id: this.id}, role: {id: roleId}, application: {id: applicationId}})
  }

  private removeUserRole(roleId: number, applicationId: number): void {
    this.existingUser!.userRoleList = this.existingUser!.userRoleList.filter(userRole => {
      return userRole.role.id !== roleId || userRole.application.id !== applicationId;
    });
  }

  onSubmit(): any {
    if(this.existingUser) {
      this.userService.updateUser(this.id!, this.existingUser);
      this.activeModal.close('updated');
    }else {
      const user: User =
        {
          id: 0,
          username: this.crudForm.get('username')!.value,
          password: this.crudForm.get('password')!.value,
          userRoleList: []
        }
      this.userService.createUser(user);
      this.activeModal.close('created');
    }
  }

}
