import { Injectable } from '@angular/core';
import {catchError, map, Observable, of, Subject, switchMap, throwError} from "rxjs";
import {Role} from "../../model/Role";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {error} from "@angular/compiler-cli/src/transformers/util";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private ssoLoginUrl = environment.ssoLoginUrl;
  private ssoVerifyToken = environment.ssoVerifyToken; // Remplacez par l'URL de connexion SSO

  private localStorageUserKey = 'currentUser'; // Clé pour stocker l'utilisateur dans le local storage
  private localStorageTokenKey = 'currentToken'

  currentUser: any;
  currentUserSubject = new Subject<any>;


  constructor(private http: HttpClient, private router: Router) { }

  initiateSSOAuthentication(): void {
    window.location.href = this.ssoLoginUrl;
  }

  handleSSORedirect(token: string): void {
    this.verifyToken(token).subscribe(
      {
        next: (user: any) => {
          this.saveCurrentUser(user);
          this.saveCurrentToken(token);
          this.router.navigate(['/']);
        },
        error: () => {
          this.initiateSSOAuthentication();
        },
        complete: () => {

        }
      }
    );

  }

  verifyToken(token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    return this.http.get<any>(this.ssoVerifyToken, { headers: headers });
  }

  isStillConnect(): void {
    const token = this.getCurrentToken();
    if(token) {
      this.verifyToken(token).subscribe({
          next: (user) => {
            this.saveCurrentUser(user);
            this.saveCurrentToken(token);
          },
          error: () => {
            this.clearLocalStorage();
            this.router.navigate(['/']);
          }
        }
      )
    }
  }

  isLoggedIn(): Observable<boolean> {

    const token = this.getCurrentToken();

    if (!token) {
      // Aucun jeton trouvé, l'utilisateur n'est pas connecté
      return of(false);
    }


    // Appeler l'API pour valider le jeton
    return this.verifyToken(token).pipe(
      map(response => {
        return !!response;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  logout(): void {
    this.clearLocalStorage();
    this.router.navigate(['/']);
  }

  private saveCurrentUser(user: any): void {
    // Sauvegarde l'utilisateur dans le local storage
    localStorage.setItem(this.localStorageUserKey, JSON.stringify(user));
  }

  private saveCurrentToken(token: string): void {
    // Sauvegarde le token dans le local storage
    localStorage.setItem(this.localStorageTokenKey, token);
    this.getCurrentUser();
  }

  getCurrentUser(): any {
    // Récupère l'utilisateur depuis le local storage
    const userString = localStorage.getItem(this.localStorageUserKey);
    this.currentUser = userString ? JSON.parse(userString) : null;
    this.emitCurrentUser();
    return this.currentUser;
  }

  emitCurrentUser(): any {
    const user = this.currentUser;
    this.currentUserSubject.next(user);
  }

  getCurrentToken(): string | null {
    // Récupère le token depuis le local storage
    return localStorage.getItem(this.localStorageTokenKey);
  }

  clearLocalStorage(): void {
    // Efface les données de l'utilisateur et du token du local storage
    localStorage.removeItem(this.localStorageUserKey);
    localStorage.removeItem(this.localStorageTokenKey);
    this.getCurrentUser();
  }


}
