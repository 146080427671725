import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMappingService {
  private errorMessages: any = {
    //PasswordValidatationException
    minimalLengthException: "Le mot de passe doit avoir au minimum 8 caractères",
    maximalLengthException: "Le mot de passe doit avoir au maximum 32 caractères",
    uppercaseException: "Le mot de passe doit avoir au moins une majuscule",
    lowercaseException: "Le mot de passe doit avoir au moins une minuscule",
    digitException: "Le mot de passe doit avoir au moins un chiffre",
    specialCharacterException: "Le mot de passe doit avoir au moins un caractère scpécial",
  };

  constructor() { }

  getErrorMessageFromBackendCode(backendErrorCode: string): string {
    return this.errorMessages[backendErrorCode] || "Une erreur inattendue s'est produite.";
  }
}
