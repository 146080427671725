import { Routes } from '@angular/router';
import {RoleListComponent} from "./component/roles/role-list/role-list.component";
import {CallbackComponent} from "./authentication/component/callback/callback.component";
import {authGuard} from "./authentication/auth.guard";
import {UserListComponent} from "./component/users/user-list/user-list.component";
import {ApplicationListComponent} from "./component/applications/application-list/application-list.component";

export const routes: Routes = [
  { path: 'role', component: RoleListComponent, canActivate: [authGuard]},
  { path: 'user', component: UserListComponent, canActivate: [authGuard]},
  { path: 'application', component: ApplicationListComponent, canActivate: [authGuard]},
  { path: 'api/callback', component: CallbackComponent },
];
